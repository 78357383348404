import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Button from "../components/button"
import H1 from "../components/h1"
import Layout from "../components/layout"
import Narrow from "../components/narrow"
import { useStaticQuery, graphql } from "gatsby"

const StyledTackPage = styled.div`
  text-align: center;
`

const TackPage = () => {
  const query = useStaticQuery(graphql`
    query tackQuery {
      image: file(relativePath: { eq: "contact.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Layout headerImage={query.image.childImageSharp.fluid} helmet="Tack">
      <StyledTackPage>
        <Narrow>
          <H1>Tack</H1>
          <p className="centered">
            Ditt meddelande har tagits emot. Vi svarar så snart vi kan.
          </p>
          <Link to="/">
            <Button>Tillbaka till hem</Button>
          </Link>
        </Narrow>
      </StyledTackPage>
    </Layout>
  )
}

export default TackPage
